<template>
  <div class="pageview">
    <div class="header">
      <div class="personal"
        style="text-align: center;">
        <van-swipe class="homebanner"
          :autoplay="3000"
          indicator-color="white">
          <van-swipe-item v-for="banner in bannerImgs"
            :key="banner"><img :src="banner" /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div style="margin:0 10px;">
      <van-grid class="wktype"
        style="padding:5px 0!important;margin:2px;"
        :border="false"
        :square="true">
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/travel')">
          <van-image class="iconimg"
            :src="require('../../assets/乡村旅游.png')"></van-image>
          <span class="wktext">乡村旅游</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/activity')">
          <van-image class="iconimg"
            :src="require('../../assets/乡村资讯.png')"></van-image>
          <span class="wktext">乡村资讯</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/register')">
          <van-image class="iconimg"
            :src="require('../../assets/疫情防控.png')"></van-image>
          <span class="wktext">疫情防控</span>
        </van-grid-item>
        <van-grid-item icon="photo-o"
          @click="gotoUrl('/partymember')">
          <van-image class="iconimg"
            :src="require('../../assets/智慧党建.png')"></van-image>
          <span class="wktext">智慧党建</span>
        </van-grid-item>
      </van-grid>
    </div>
    <div style="width: 95%; margin: auto;display: grid;margin-bottom: 1em;">
      <div style="float:left;line-height: 35px;">
        <div class="typeTitle"
          style="float:left;color:#fe5b47;padding:0 5px;font-size: 14pt;">&nbsp;附近景点</div>
        <div class="van-ellipsis--l"
          style="width:60%;float:left;">
          <span style="color:#FE5B47;">&nbsp;&nbsp;&nbsp;HOT&nbsp;</span>
          <span style="color:#666666;">龙门石窟十一门票团购价</span>
        </div>
        <div style="float:right;color:#999999;"
          @click="gotoUrl('/article')">更多&nbsp;</div>
      </div>
    </div>
    <div style="width:95%;margin:auto;">
      <div class="grouptitle">&nbsp;</div>
      <div class="typeTitle"
        style="margin-bottom:0.7em;">常用服务</div>
      <div style="display: flow-root;font-size:12pt;">
        <div class="changyou01">
          <div style="margin: 10px 10px 5px 10px;color:#fe8274;font-weight:550;">快乐乡村体验游</div>
          <div style="margin-left: 10px;color:#fe8274;font-size:11px;">乡村旅游信息简介</div>
        </div>
        <div class="changyou02">
          <div style="margin: 10px 0px 2px 10px;color:#4cde96;font-weight:550;">政务服务</div>
          <div style="margin-left: 10px;color:#4cde96;font-size:11px;">政务服务 据实办事</div>
        </div>
        <div class="changyou03">
          <div style="margin: 10px 0px 2px 10px;color:#fab881;font-weight:550;">我为群众办实事</div>
          <div style="margin-left: 10px;color:#fab881;font-size:11px;">意见建议 解忧除难</div>
        </div>
      </div>
    </div>
    <div style="width:95%;margin:auto;">
      <div>
        <div class="grouptitle">&nbsp;</div>
        <div class="typeTitle"
          style="margin-top:1em;">通知公告
          <span style="color:#999999;float: right;font-size:12pt;font-weight: 300;"
            @click="gotoUrl('/article')">更多</span>
        </div>
      </div>
      <div class="information">
        <div class="center"
          v-for="item in NoticeList"
          :key="item.index">
          <div @click="gotoUrl('/article/detail/' + item.RIId)">

            <div class="Content">

              <div class="ContentB">
                <div class="centerTitle van-ellipsis--l2">{{ item.Title }}</div>
              </div>
              <div style="width: 7%;display: inline-block;"></div>
              <div class="ContentA"
                ref="getHeight">
                <img v-if="item.ThematicUrl"
                  style="border-radius:5px;"
                  :src="item.ThematicUrl"
                  alt="" />
              </div>
              <span class="centertime"
                style="float: left;margin-top: -5px;color:#999999;">{{ item.IssTime }}</span>
            </div>
          </div>
          <!-- <div style="border:1px solid red;"></div> -->
        </div>

        <!-- <van-empty description="暂无通知"
          v-if="!NoticeList.length" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/config/index";
import wx from "weixin-js-sdk";
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetAppletInfo, WeGetRealInfoPage } from "@/api/index";
export default {
  components: {

  },
  data () {
    return {
      appInfo: {},
      bannerImgs: [],
      active: 0,
      NoticeList: [
        { RIId: '1', Title: '第三方水电费水电费多所付所多付所多付付付付付付付付付付付付', IssTime: '2022-05-22', ThematicUrl: 'https://api.qxzhqm.cn//upload/images/2022/5/185a1721-af0.png' },
        { RIId: '1', Title: '第三方水电费水电费多所付所多付所多付付付付付付付付付付付付', IssTime: '2022-05-22', ThematicUrl: 'https://api.qxzhqm.cn//upload/images/2022/5/185a1721-af0.png' },
      ],
      area: ''
    };
  },
  created () {
    this.getAppInfo()
    this.getNoticeList()
  },
  methods: {
    // 小程序展示数据
    getAppInfo () {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1Url;
          imgs[1] = list.data.SysPic2Url;
          // imgs[2] = list.data.SysPic3Url;
          this.bannerImgs = imgs;
          this.appInfo = list.data;
        }
      });
    },
    // 通知公告
    getNoticeList () {
      WeGetRealInfoPage({ page: 1, limit: 5, sortId: 0 }).then((res) => {
        this.NoticeList = res.data.data;
      });
    },
    //跳转到公共页面
    gotoUrl (path) {
      if (window.__wxjs_environment === "miniprogram") {
        window.wx.miniProgram.navigateTo({
          url: "../public/goto?path=" + path,
        });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>
.grouptitle {
  border-left: 4px solid #4add93;
  float: left;
  margin: 5px;
}
.homebanner img {
  width: 95%;
  vertical-align: top;
  margin: auto;
  border-radius: 5px;
}
.wktype .iconimg {
  margin-bottom: 5px;
  width: 65%;
}
.typeTitle {
  font-size: 16pt;
  font-weight: bold;
}
.wktext {
  font-size: 11pt;
}
.changyou01 {
  border-radius: 5px;
  width: 47%;
  height: 140px;
  margin-left: 5px;
  margin-right: 10px;
  float: left;
  background-color: #ffe9ec;
  background-image: url('https://xczxapi.zhsq.cloud//upload/images/2022/5/0d1fca90-d14.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.changyou02 {
  border-radius: 5px;
  width: 47%;
  height: 65px;
  margin-bottom: 9px;
  float: left;
  background-color: #dbfdf6;
  background-image: url('https://xczxapi.zhsq.cloud//upload/images/2022/5/26f4cc03-4af.png');
  background-size: 100%;
  background-repeat: no-repeat;
}
.changyou03 {
  border-radius: 5px;
  width: 47%;
  height: 65px;
  float: left;
  background-color: #ffedd6;
  background-image: url('https://xczxapi.zhsq.cloud//upload/images/2022/5/8123661c-cec.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

.ContentA {
  display: inline-block;
  width: 32%;
  margin-left: 15px;
  margin-top: 3px;
}
.van-ellipsis--l {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
.van-ellipsis--l2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 16px;
}
</style>